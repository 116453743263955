export default [
  {
    header: 'Menu',
  },
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: "Documentos",
    icon: "FileIcon",
    children: [
      {
        title: "Consulta Histórica",
        route: "documents",
        icon: "ClockIcon",
        action: "read",
        resource: "documents",
      },

      {
        title: "Agregar Documento",
        route: "documents-add",
        icon: "UploadIcon",
        action: "create",
        resource: "documents",
      },
    ],
  },
  {
    title: 'Estadísticas',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Roedores',
        route: 'reg-roadents-list',
        icon: 'ListIcon',
        action: "update",
        resource: "stats",
      },
      {
        title: 'Chagas',
        route: 'reg-chagas-list',
        icon: 'ListIcon',
        action: "update",
        resource: "stats",
      },
      {
        title: 'Malaria',
        route: 'reg-malaria-list',
        icon: 'ListIcon',
        action: "update",
        resource: "stats",
      },
      {
        title: 'Leishmaniasis',
        route: 'reg-leishmaniasi-list',
        icon: 'ListIcon',
        action: "update",
        resource: "stats",
      },
    ],
  },
]
